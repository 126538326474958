h1, h2, h3, h4, h5, h6 {
  font-family: 'Rokkitt', serif;
}

p, a {
  font-family: 'Spartan', sans-serif;
}

/* Generic styles */
html {
  scroll-behavior: smooth;
}

a {
  background-color: #1C2705;
  text-decoration: none;
  color: #628E56;
  border-radius: .25rem;
  text-align: center;
  display: inline-block;
  transition: all .3s;
}

a:hover {
  opacity: .6;
}

body {
  background-image: linear-gradient(to bottom, #628e56, #517240, #3f582c, #2d3e19, #1c2705);
}

/* Styles for the hero image */
.hero {
  padding: 4rem 2rem;
  /* grid styles */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
}

.hero > h1 {
  font-size: 3rem;
  padding-bottom: 1rem;
} 

.hero-1 {
  /* Photo by @cwg on Unsplash */
  background: url('./images/cg-JhzOrvKBetE-unsplash.jpg') center;
  background-size: cover;
}

.hero > * {
  color: #fde3a7;
}

.hero-2 {
  /* Photo by @jtylernix on Unsplash */
  background: url('./images/tyler-nix-V3dHmb1MOXM-unsplash.jpg') center;
  background-size: cover;
}

.cards {
  padding: 2rem;
}

.cards > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
}

.cards > ul > li {
  border: 1px solid #1C2705;
  border-radius: .5rem;
  list-style-type: none;
}

.cards > ul > li > p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem .75rem;
  color: #628E56;
}

.cards > ul > li > a {
  padding: .5rem 1rem;
  margin: .5rem;
}

footer {
  background-color: #1C2705;
  padding: .75rem;
  color: #628E56;
  text-align: center;
  font-size: .75rem;
}

.avatar-image {
  border-radius: 50%;
  object-fit: cover;
}

.map {
	width: 100%;
	object-fit: cover;
}